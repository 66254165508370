import React, {useState, useEffect} from 'react';
// import{Link} from 'react-router-dom';
import {Flex, Heading,Wrap,WrapItem,VStack,IconButton,useDisclosure,Link, Button } from '@chakra-ui/react';
import Navigation from '../Menu/Navigation';
// import Button from '../Button/CustomeButton';
import { NavLink } from 'react-router-dom';
import { FiMenu, FiX  } from "react-icons/fi";
import { defaultHeaderProps } from './Header.type';
import "./header.css";

function Header(props) {
  const { isOpen,onClose, onToggle } = useDisclosure();
  const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
          const currentScroll = window.scrollY; 
            setIsSticky(currentScroll > 50);
        };

        window.addEventListener('scroll', handleScroll);
        // Cleanup function
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

  return (
    <Flex alignContent="space-between" width={"full"}alignItems={"center"}justifyContent={"center"} paddingStart={{sm:'0', md:"0"}} paddingEnd={{sm:'0', md:"0"}}  className={`navbar ${isSticky ? 'is_sticky' : ''}`} backgroundColor={isOpen && "#fff"} borderBottom={isOpen && "1px solid #78787833"}>
      {props.isLogo || props.isNavigation || props.isButton ? (<>
        
         <Flex height={{base:'70px', md:'70px', lg:"90px"}} alignItems={"center"} className='togelbuttn_inner' >
            <IconButton icon={isOpen ? <FiX /> : <FiMenu display={"inline-block"}/>} className="_togelbuttn"  fontSize='30px' height="35px"  textAlign="center" padding={"0"} margin="0 !important" onClick={onToggle} color={ isSticky ? "#000" :  isOpen ? "#000" :"#fff"}  />
          </Flex>

        <Wrap width="100%" className='headerWrap -hader'>
          
          {props.isLogo &&
            <WrapItem textAlign={{lg:"center", xl:"left"}}  height={{base:'70px', md:'70px', lg:"90px"}} alignItems={"center"} justifyContent={"left"} className='logo-section'>
              <Heading className='_logo' as={"h1"} color={ isSticky ? "#000" : "#fff"} margin={{sm:"0 auto",lg:"0"}} fontSize={{sm:'xs', md:'md', lg:"lg",xl:"lg"}}><Link href='/' _hover="textDecoration:none" color={isOpen && '#000'} >PERPETUA</Link></Heading>
            </WrapItem>
          }

          {props.isNavigation &&
              <VStack  align={{md:"left"}} height='100%' width={{ sm:"0", lg:'0'}} paddingLeft={{lg: "0"}} >
                <Navigation isDrawerOpen={isOpen} closeDrawer={onClose} isSticky={isSticky} />
              </VStack> 
            }

          {/*<WrapItem   textAlign={{ lg:"center", xl:"left"}}  height={{base:'70px', md:'70px', lg:"90px"}} alignItems={"center"}
            justifyContent={{ sm:"left", lg:"center"}}>
             {props.isButton &&
              <VStack align={{md:"left"}} flexDirection='row'>
                <Flex paddingRight='7px' display={{sm:"none", lg:"flex"}}>
                  <NavLink to='/pricing'>
                    <Button
                      borderColor='Red' color={ isSticky ? "#000" :  isOpen ? "#000" :"#fff"} borderRadius='25px'
                      borderWidth='2px' _hover={{bg:'Red'}} textTransform='uppercase'  width={{sm:"82px", md:'110px'}} 
                      fontSize={{sm:"10px", md:"12px"}} variant='outline' marginRight={{sm:'7px', md:"10px"}}
                    >Buy Credits</Button>
                  </NavLink>
                  <NavLink to='/timetable'>
                    <Button
                      borderColor='Red' color={ isSticky ? "#000" :  isOpen ? "#000" :"#fff"} borderRadius='25px'
                      borderWidth='2px' _hover={{bg:'Red'}} textTransform='uppercase' 
                      fontSize={{sm:"10px", md:"12px"}} variant='outline' width={{sm:"82px", md:'110px'}} 
                    >Book Class</Button>
                  </NavLink>
                </Flex>
              </VStack>
            } 
          </WrapItem>*/}

        </Wrap>
        <VStack  flexDirection='row' height={{base:'70px', md:'70px', lg:"90px"}} alignItems={"center"}   className='header_button_wrap'>
          <Flex paddingRight='7px' display={{sm:"none", lg:"flex"}}>
            <NavLink to='/pricing'>
              <Button
                borderColor='Red' color={ isSticky ? "#000" :  isOpen ? "#000" :"#fff"} borderRadius='25px'
                borderWidth='2px' _hover={{bg:'Red'}} textTransform='uppercase'  width={{sm:"82px", md:'110px'}} 
                fontSize={{sm:"10px", md:"12px"}} variant='outline' marginRight={{sm:'7px', md:"10px"}}
              >Buy Credits</Button>
            </NavLink>
            <NavLink to='/timetable'>
              <Button
                borderColor='Red' color={ isSticky ? "#000" :  isOpen ? "#000" :"#fff"} borderRadius='25px'
                borderWidth='2px' _hover={{bg:'Red'}} textTransform='uppercase' 
                fontSize={{sm:"10px", md:"12px"}} variant='outline' width={{sm:"82px", md:'110px'}} 
              >Book Class</Button>
            </NavLink>
          </Flex>
        </VStack>
      </> ) : null}
    </Flex>
  );
}

export default Header;
Header.defaultProps = defaultHeaderProps
