import React from 'react';
import {Flex,Container,Box} from '@chakra-ui/react';
import Heading from '../Heading/Heading';
// import ClassCard from '../Classcard/ClassCard';
import ClasscardV2 from '../Classcard/ClasscardV2';
import * as Style from './ClassListing.style';

function ClassListing(){

  return (
   <>
        <Flex 
            p={{sm:'30px 0px', md:'50px 0px'}}
            backgroundColor='#fff'
            // borderTop='1px solid #232323'
            borderBottom='1px solid #e8e4e4d1'
        >
            <Container maxW={"container.xl"}>
                <Box textAlign='left'>
                    <Heading 
                        marginBottom='50px' 
                        variant="medium"
                        title="Make Your Move"
                        className='wow animate fadeInUp' 
                        color='#000'
                        >
                    </Heading>
                </Box>
                <Style.ClasslistingBody>
                    <ClasscardV2
                        width={{lg:'31%',md:'46%',sm:'100%'}}
                        title='CROSSFIT'
                        subtitle='COACHING. COMMUNITY. PASSION'
                        imageUri='/images/homeCrossfit.webp'
                        link="/crossfit-dublin"
                        margin="0"
                        transform="uppercase"
                        alt="crossfit - perpetua fitness"
                        height='10%'
                        objectFit='cover'
                        objectPosition='center'
                        colorCode='#000'
                    />
                    <ClasscardV2
                        width={{lg:'31%',md:'46%',sm:'100%'}}
                        title='STUDIOS'
                        subtitle='DUBLINS BEST BOUTIQUE STUDIO EXPERIENCE'
                        imageUri='/images/homeStudio.webp'
                        link="/fitness-classes-dublin"
                        margin={{lg:'60px 0px 0px', md:'60px 0px 0px', sm:'30px 0px 0px'}}
                        transform="uppercase"
                        alt="studios - perpetua fitness"
                        height='10%'
                        objectFit='cover'
                        objectPosition='center'
                        colorCode='#000'
                    />
                    <ClasscardV2
                        width={{lg:'31%',md:'46%',sm:'100%'}}
                        title='Personal Training '
                        subtitle='WE KNOW EXACTLY WHAT IT TAKES TO GET RESULTS'
                        imageUri='/images/online_training.webp'
                        link="/personal-training-dublin"
                        margin={{lg:'120px 0px 0px', md:'0px', sm:'30px 0px 0px'}}
                        transform="uppercase"
                        alt="personal - perpetua fitness"
                        height='10%'
                        objectFit='cover'
                        objectPosition='center'
                        colorCode='#000'
                    />
                    <ClasscardV2
                        width={{lg:'31%',md:'46%',sm:'100%'}}
                        title='JUSTGYM'
                        subtitle='FULLY EQUIPPED GYM'
                        imageUri='/images/just_gym_new.webp'
                        link="/luxury-gym-dublin-city-centre"
                        margin={{lg:'0px 0px 0px', md:'60px 0px 0px', sm:'30px 0px 0px'}}
                        transform="uppercase"
                        alt="justgym - perpetua fitness"
                        height='10%'
                        objectFit='cover'
                        objectPosition='center'
                        colorCode='#000'
                    />
                    <ClasscardV2
                        width={{lg:'31%',md:'46%',sm:'100%'}}
                        title='Hyrox'
                        subtitle='THE WORLD SERIES OF FITNESS RACING'
                        imageUri='/images/homeHyrox.webp'
                        link="/hyrox-dublin"
                        margin={{lg:'60px 0px 0px', md:'60px 0px 0px', sm:'30px 0px 0px'}}
                        transform="uppercase"
                        alt="hyrox - perpetua fitness"
                        height='10%'
                        objectFit='cover'
                        objectPosition='center'
                        colorCode='#000'
                    />
                    <ClasscardV2
                        width={{lg:'31%',md:'46%',sm:'100%'}}
                        title='Online Training'
                        subtitle='The Best Online Program By Experienced Coaches'
                        imageUri='/images/iphone.webp'
                        link="/online-gym-programming"
                        margin={{lg:'120px 0px 0px', md:'60px 0px 0px', sm:'30px 0px 0px'}}
                        transform="uppercase"
                        alt="online-programing - perpetua fitness"
                        height='10%'
                        objectFit='cover'
                        objectPosition='center'
                         colorCode='#000'
                    />
                </Style.ClasslistingBody>
            </Container>
        </Flex>
   </>
  )


}

export default ClassListing;
