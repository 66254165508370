import * as React from 'react'
import { Box,Link} from '@chakra-ui/react'
import{defaultPriceBoxProps} from './PriceBox.type';
import Heading from '../Heading/Heading';
import Text from '../SubHeading/SubHeading';

import Button from '../Button/CustomeButton';
function PriceBox(props) {
  return (
    <>

        <Box>
            {props.manheadind && 
              <Box display='flex' alignItems='flex-end' margin='0px 0px 20px'>
                <Heading as='h2' title={props.manheadind} variant='medium' color={props.color} lineHeight='1' ></Heading>
              </Box>
            }
            {props.isHeading && <Box display='flex' alignItems='flex-start' margin='0px 0px 20px'>
              
              <Heading as='h2' title={props.priceValue} variant='extralarge' color={props.color} lineHeight='0.9' className='_price' ></Heading>
              <Heading as='h2' title={props.currency} variant='medium' color={props.color} lineHeight='1' ></Heading>
              <Heading as='h2' title={props.time} variant='medium' color={props.color} lineHeight='1' className='_time' ></Heading>
              </Box>
            }
            {props.issubHeading &&<Heading as='h3' color={props.color} lineHeight='0.9' title={props.subheading} variant='small' margin='0px 0px 15px'></Heading>}
            {props.isDescription &&<Text color={props.color} as='p' title={props.description} width={props.width}></Text>}
            {props.isButton &&<Link href={props.link} isExternal={props.targetBlank}>
               {/*  <LinkButton color={props.color} title={props.buttonText} Imguri='/images/red_arrow.svg' padding={props.buttonPading} margin='30px 0px 0px' border={props.border} borderColor={props.borderColor} ></LinkButton> */}
                <Button margin='30px 0px 0px' className="wow fadeInUp" title={props.buttonText} color={props.color} />
            </Link>}

        </Box>
    </>
  );
}

export default PriceBox;
PriceBox.defaultProps = defaultPriceBoxProps