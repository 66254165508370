import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  ListItem,
  UnorderedList,
  Box,
  IconButton,
  HStack,
  Image,
  Link,
  SimpleGrid,
  GridItem,
  Flex,
} from "@chakra-ui/react";
import Text from "../SubHeading/SubHeading";
import Heading from '../Heading/Heading';
import { ChevronDownIcon } from "@chakra-ui/icons";

function MenuList(props) {
  // const menuLists = [
  //     {
  //         title: "LOCATIONS",
  //         link: "/windmill-lane",
  //         targetBlank: false,
  //         submenu: [
  //             {
  //                 title: "Windmill Lane",
  //                 link: "/windmill-lane",
  //                 targetBlank: false,
  //             },
  //             {
  //                 title: "Lennox Street",
  //                 link: "/lennox-street",
  //                 targetBlank: false,
  //             },

  //         ],
  //     },
  //     {
  //         title: "STUDIOS",
  //         link: "/fitness-classes-dublin",
  //         targetBlank: false,
  //         submenu: [
  //             {
  //                 title: "Tread & Shred",
  //                 link: "/tread-and-shred",
  //                 targetBlank: false,
  //             },
  //             {
  //                 title: "Rhythm Ride",
  //                 link: "/spin-class-dublin",
  //                 targetBlank: false,
  //             },
  //             {
  //                 title: "Ride 45",
  //                 link: "/spin-studio-dublin",
  //                 targetBlank: false,
  //             },
  //             {
  //                 title: "RUMBLE",
  //                 link: "/boxing-class-dublin",
  //                 targetBlank: false,
  //             },
  //             {
  //                 title: "First Timers",
  //                 link: "/first-timers",
  //                 targetBlank: false,
  //             },
  //             {
  //                 title: "TIMETABLE",
  //                 link: "/timetable",
  //                 targetBlank: false,
  //             },
  //             {
  //                 title: "PRICING",
  //                 link: "/pricing/studios",
  //                 targetBlank: false,
  //             },
  //         ],
  //     },
  //     {
  //         title: "CROSSFIT",
  //         link: "/crossfit-dublin",
  //         targetBlank: false,
  //         submenu: [
  //             {
  //                 title: "GET STARTED",
  //                 link: "/crossfit-dublin",
  //                 targetBlank: false,
  //             },
  //             {
  //                 title: "Beginners",
  //                 link: "/crossfit-dublin/beginners",
  //                 targetBlank: false,
  //             },
  //             {
  //                 title: "Visiting? Drop In",
  //                 link: "/crossfit-dublin/drop-in",
  //                 targetBlank: false,
  //             },
  //             {
  //                 title: "TIMETABLE",
  //                 link: "/timetable",
  //                 targetBlank: false,
  //             },
  //             {
  //                 title: "PRICING",
  //                 link: "/pricing/crossfit",
  //                 targetBlank: false,
  //             },
  //         ],
  //     },
  //     {
  //         title: "JUSTGYM",
  //         link: "/luxury-gym-dublin-city-centre",
  //         targetBlank: false,
  //         submenu: [
  //             {
  //                 title: "Get Started",
  //                 link: "/luxury-gym-dublin-city-centre",
  //                 targetBlank: false,
  //             },
  //             {
  //                 title: "PRICING",
  //                 link: "/pricing/just-gym",
  //                 targetBlank: false,
  //             },
  //         ],
  //     },
  //     {
  //         title: "PERSONAL TRAINING",
  //         link: "/personal-training-dublin",
  //         targetBlank: false,
  //         submenu: [
  //             {
  //                 title: "PERSONAL TRAINING",
  //                 link: "/personal-training-dublin",
  //                 targetBlank: false,
  //             },
  //             {
  //                 title: "NUTRITION COACHING",
  //                 link: "/nutrition-coaching-dublin",
  //                 targetBlank: false,
  //             },
  //             // {
  //             //     title: "Golf",
  //             //     link: "/golf-fitness-training-dublin",
  //             //     targetBlank: false,
  //             // },
  //             {
  //                 title: "Pricing",
  //                 link: "/pricing/personal-training",
  //                 targetBlank: false,
  //             },

  //         ],
  //     },
  //     {
  //         title: "LADIES",
  //         link: "",
  //         targetBlank: false,
  //         submenu: [
  //             // {
  //             //     title: "Ladies Group",
  //             //     link: "/ladies-gym-dublin",
  //             //     targetBlank: false,
  //             // },
  //             {
  //                 title: "PRE/POST NATAL",
  //                 link: "/pregnancy-exercise-dublin",
  //                 targetBlank: false,
  //             },
  //             {
  //                 title: "PRICING",
  //                 link: "/pricing/prepost-natal",
  //                 targetBlank: false,
  //             },
  //         ],
  //     },
  //     {
  //         title: "HYROX" ,
  //         link: "/hyrox-dublin",
  //         targetBlank: false,
  //         submenu: [
  //             {
  //                 title: "GET STARTED",
  //                 link: "/hyrox-dublin",
  //                 targetBlank: false,
  //             },
  //             {
  //                 title: "TIMETABLE",
  //                 link: "/timetable",
  //                 targetBlank: false,
  //             },
  //             {
  //                 title: "PRICING",
  //                 link: "/pricing/hyrox",
  //                 targetBlank: false,
  //             },
  //         ],
  //     },
  //     {
  //         title: "ONLINE TRAINING",
  //         link: "/online-gym-programming",
  //         targetBlank: false,
  //         submenu: [
  //             {
  //                 title: "ONLINE PROGRAMMING",
  //                 link: "online-gym-programming",
  //                 targetBlank: false,
  //             },
  //             {
  //                 title: "PERPETUA TRAINING",
  //                 link: "https://perpetuatraining.com/",
  //                 targetBlank: true,
  //             },
  //              {
  //                 title: "PRICING",
  //                 link: "/pricing/online-programming",
  //                 targetBlank: false,
  //             },
  //         ],
  //     },
  //     {
  //         title: "MEDIA",
  //         // link: "https://www.youtube.com/@perpetuafitness",
  //         link:"/blog",
  //         targetBlank: false,
  //         submenu: [
  //             {
  //                 title: "PODCAST",
  //                 link: "/podcast",
  //                 targetBlank: false,
  //             },
  //             {
  //                 title: "MOVEMENT LIBRARY",
  //                 link: "https://youtube.com/@perpetuafitness",
  //                 targetBlank: true,
  //             },
  //             {
  //                 title: "BLOG",
  //                 link: "/blog",
  //                 targetBlank: false,
  //             },
  //         ],
  //     },
  // ];

  const mobileMenuLists = [
    {
      title: "WORKOUTS",
      link: "/fitness-classes-dublin",
      targetBlank: false,
      submenu: [
        {
          title: "Tread & Shred",
          link: "/tread-and-shred",
          subTitle: "Group fitness experience ",
          targetBlank: false,
          image: "/images/megaMenuIcons/gym1.png",
        },
        {
          title: "Rhythm Ride",
          link: "/spin-class-dublin",
          subTitle: "Spin Class Dublin",
          targetBlank: false,
          image: "/images/megaMenuIcons/health1.png",
        },
        {
          title: "Ride 45",
          link: "/spin-studio-dublin",
          subTitle: "Spin Class Dublin",
          targetBlank: false,
          image: "/images/megaMenuIcons/health1.png",
        },
        {
          title: "RUMBLE BOXING",
          link: "/boxing-class-dublin",
          subTitle: "Boxing Class Dublin",
          targetBlank: false,
          image: "/images/megaMenuIcons/boxing1.png",
        },
        {
          title: "CROSSFIT",
          link: "/crossfit-dublin",
          subTitle: "Ireland's First CrossFit Affiliate",
          targetBlank: false,
          image: "/images/megaMenuIcons/weight-lifting1.png",
        },
        {
          title: "HYROX",
          link: "/hyrox-dublin",
          subTitle: "Ireland's First Hyrox Affiliate ",
          targetBlank: false,
          image: "/images/megaMenuIcons/running-man1.png",
        },
        {
          title: "JUSTGYM",
          link: "/luxury-gym-dublin-city-centre",
          subTitle: "Premium fitness facility",
          targetBlank: false,
          image: "/images/megaMenuIcons/weights1.png",
        },
        {
          title: "ONLINE PROGRAMMING",
          link: "/online-gym-programming",
          subTitle: "Start online training",
          targetBlank: false,
          image: "/images/megaMenuIcons/weight1.png",
        },
        {
          title: "PRE/POST NATAL",
          link: "/pregnancy-exercise-dublin",
          subTitle: "Train with qualified coaches",
          targetBlank: false,
          image: "/images/megaMenuIcons/pregnant1.png",
        },
      ],
    },
    {
      title: "STUDIOS",
      link: "/windmill-lane",
      targetBlank: false,
      submenu: [
        {
          title: "Windmill Lane",
          link: "/windmill-lane",
          subTitle: "D02 kX66",
          targetBlank: false,
          image: "/images/megaMenuIcons/gps1.png",
        },
        {
          title: "Lennox Street",
          link: "/lennox-street",
          subTitle: "D02 FK02",
          targetBlank: false,
          image: "/images/megaMenuIcons/gps1.png",
        },
      ],
    },
    {
      title: "ONE TO ONE",
      link: "/personal-training-dublin",
      targetBlank: false,
      submenu: [
        {
          title: "PERSONAL TRAINING",
          link: "/personal-training-dublin",
          subTitle: "Guaranteed Results",
          targetBlank: false,
          image: "/images/megaMenuIcons/lifting1.png",
        },
        {
          title: "NUTRITION COACHING",
          link: "/nutrition-coaching-dublin",
          subTitle: "Learn the fundamentals",
          targetBlank: false,
          image: "/images/megaMenuIcons/nutrition1.png",
        },
        {
          title: "INBODY ANALYSIS",
          link: "/inbody",
          subTitle: "Precise Health Scan",
          targetBlank: false,
          image: "/images/megaMenuIcons/body-scan1.png",
        },
      ],
    },
    {
      title: "PRIVATE HIRE",
      link: "",
      targetBlank: false,
      submenu: [
        {
          title: "corporate",
          link: "/corporate-wellness",
          subTitle: "corporate-wellness",
          targetBlank: false,
          image: "/images/megaMenuIcons/corporate.png",
        },
        {
          title: "Private Hire",
          link: "/private-gym-hire-dublin",
          subTitle: "Private Hire",
          targetBlank: false,
          image: "/images/megaMenuIcons/privateHire.png",
        },
        {
          title: "Residential page",
          link: "/residential-services",
          subTitle: "Residential page",
          targetBlank: false,
          image: "/images/megaMenuIcons/residentialService.png",
        },
      ]
    },
    {
      title: "FIRST TIMERS",
      link: "/first-timers",
      targetBlank: false,
      submenu: [
        {
          title: "Tread & Shred",
          link: "/tread-and-shred-first-timers",
          subTitle: "Our signature group fitness experience",
          targetBlank: false,
          image: "/images/megaMenuIcons/gym1.png",
        },
        {
          title: "Rumble Boxing",
          link: "/rumble-first-timers",
          subTitle: "Rumble Boxing",
          targetBlank: false,
          image: "/images/megaMenuIcons/boxing1.png",
        },
        {
          title: "Rhythm Ride",
          link: "/ride-first-timers",
          subTitle: "More than a spin class. An experience",
          targetBlank: false,
          image: "/images/megaMenuIcons/health1.png",
        },
        {
          title: "Ride 45",
          link: "/ride45-first-timers",
          subTitle: "Dublin best spin class",
          targetBlank: false,
          image: "/images/megaMenuIcons/health1.png",
        },
        {
          title: "Hyrox",
          link: "/hyrox-first-timers",
          subTitle: "Classes preparing you for a HYROX event",
          targetBlank: false,
          image: "/images/megaMenuIcons/running-man1.png",
        },
        {
          title: "Crossfit beginners",
          link: "/crossfit-dublin/beginners",
          subTitle: "CrossFit Academy Course",
          targetBlank: false,
          image: "/images/megaMenuIcons/weight-lifting1.png",
        },
        {
          title: "CrossFit Drop In",
          link: "/crossfit-dublin/drop-in",
          subTitle: "Drop-In + Visitors",
          targetBlank: false,
          image: "/images/megaMenuIcons/weight-lifting1.png",
        },
      ]
    },
    {
      title: "COMMUNITY",
      link: "/blog",
      targetBlank: false,
      submenu: [
        {
          title: "BLOG",
          link: "/blog",
          subTitle: "Read our latest news",
          targetBlank: false,
          image: "/images/megaMenuIcons/blogger1.png",
        },
        {
          title: "PODCAST",
          link: "/podcast",
          subTitle: "Stay up to date",
          targetBlank: false,
          image: "/images/megaMenuIcons/podcast1.png",
        },
        {
          title: "TEAM",
          link: "/team",
          subTitle: "Meet our coaches",
          targetBlank: false,
          image: "/images/megaMenuIcons/group-chat1.png",
        },
        {
          title: "OUR STORY",
          link: "/story",
          subTitle: "Our values and culture",
          targetBlank: false,
          image: "/images/megaMenuIcons/hiring1.png",
        },

        {
          title: "Movement Library",
          link: "https://www.youtube.com/@perpetuafitness",
          subTitle: "Watch our latest content",
          targetBlank: true,
          image: "/images/megaMenuIcons/stretching-exercises1.png",
        },
        {
          title: "Testimonials",
          link: "/perpetua-testimonials",
          subTitle: "What Our Members Say",
          targetBlank: false,
          image: "/images/megaMenuIcons/satisfaction1.png",
        },
      ],
    },
  ];

  const [menuId, setMenuId] = useState(null);

  const handelSubmenu = (str) => () => {
    if (menuId == str) {
      setMenuId(null);
    } else {
      setMenuId(str);
    }
  };

  const menuClos = ["col1", "col2", "col3", "col4"];

  return (
    <>
      <Flex className="menu-body" flexDirection={{sm:"column", md:"row"}} padding={{ sm: "10px 0", lg: "0px 30px 30px", xl: "0px 43px 30px" }} >
        <UnorderedList
          p="0"
          m="0"
          display='grid'
          gridTemplateColumns={{ sm: "repeat(2, 1fr)", xl: "repeat(4, 1fr)" }}
          width={{ sm: "100%", md: '70%', xl:"65%" }}
          gap='20px'
        >
          {menuClos.map((menuClos) => (
            <Box>
              {mobileMenuLists.map((item, index) => {
                if (
                  (menuClos === 'col1' && (index === 0)) ||
                  (menuClos === 'col2' && (index === 1 || index === 2 ||  index === 3)) ||
                  (menuClos === 'col3' && (index === 4)) ||
                  (menuClos === 'col4' && (index === 5))
                ) {

                  return (
                    <ListItem
                      key={index}
                      listStyleType="none"
                      fontSize={{ base: "18px", md: "18px", lg: "14px", xl: "16px" }}
                      display={{ md: "block", lg: "block" }}
                      marginBottom={{ sm: "15px", md: "0" }}
                      alignItems={{ lg: "center" }}
                      paddingTop='30px'
                    >
                      <Box className="menuDdBtn">
                        {item.targetBlank == true ? (
                          <Link
                            target={item.targetBlank ? "_blank" : ""}
                            className="nav-link"
                            onClick={props.closeDrawer}
                            href={item.link}
                            color='#D12C41'
                          >
                            {item.title}
                          </Link>
                        ) : item.link == "" ? (
                          <Link
                            className="nav-link"
                            onClick={props.closeDrawer}
                            to={item.link}
                            color='#D12C41'
                          >
                            {item.title}
                          </Link>
                        ) : (
                          <NavLink
                            target={item.targetBlank ? "_blank" : ""}
                            className="nav-link"
                            onClick={props.closeDrawer}
                            to={item.link}
                            style={{ color: '#D12C41' }}
                          >
                            {item.title}
                          </NavLink>
                        )}
                      </Box>
                      {item?.submenu && (
                        <Box
                          display='flex'
                          flexDirection='column'
                          gap={{ sm: "10px", lg: '22px' }}
                          paddingTop={{ lg: '20px' }}
                        >
                          {item?.submenu.map((subitem, subindex) => {
                            return subitem.targetBlank == true ? (
                              <Link
                                target={subitem.targetBlank ? "_blank" : ""}
                                key={"sb" + subindex}
                                className="nav-link"
                                onClick={props.closeDrawer}
                                href={subitem.link}
                              >
                                <HStack alignItems="flex-start">
                                  <Image
                                    src={
                                      subitem?.image
                                        ? subitem?.image
                                        : "./images/megaMenuIcons/pti.png"
                                    }
                                    width="18px"
                                    paddingTop="9px"
                                  />
                                  <Box paddingLeft="10px" color={props.isSticky && '#000'} textTransform='uppercase'>
                                    {subitem.title}
                                    <Text
                                      title={subitem.subTitle}
                                      fontSize="12px"
                                      textTransform="capitalize"
                                      color="#787878"
                                    />
                                  </Box>
                                </HStack>
                              </Link>
                            ) : (
                              <NavLink
                                target={subitem.targetBlank ? "_blank" : ""}
                                key={"sb" + subindex}
                                className="nav-link"
                                onClick={props.closeDrawer}
                                to={subitem.link}
                              >
                                <HStack alignItems="flex-start">
                                  <Image
                                    src={
                                      subitem?.image
                                        ? subitem?.image
                                        : "./images/megaMenuIcons/pti.png"
                                    }
                                    width="18px"
                                    paddingTop="9px"
                                  />
                                  <Box paddingLeft="10px" color={props.isSticky && '#000'} textTransform='uppercase'>
                                    {subitem.title}
                                    <Text
                                      title={subitem.subTitle}
                                      fontSize="12px"
                                      textTransform="capitalize"
                                      color="#787878"
                                    />
                                  </Box>
                                </HStack>
                              </NavLink>
                            );
                          })}
                        </Box>
                      )}
                    </ListItem>
                  );
                }
              })}
            </Box>
          ))}
        </UnorderedList>
        <Box width={{ xl:"9%"}}></Box>
        <Box width={{sm:"100%", md:'30%', xl:"26%"}} paddingTop={{sm:"40px", md:"0", lg:"30px"}}>
          <Heading title='Get our best intro offer 3 FOR €33' variant='small' lineHeight='1' padding='0 0 30px' />
          <Image src='/images/menu-banner-2.webp' alt='menu price' className='' />
          <Text title='try 3 classes for €33' textTransform='upperCase' padding='10px 0 0' />
          <Link href='https://cart.mindbodyonline.com/sites/76074/cart/add_service?mbo_item_id=104433' isExternal='true'>
            <Flex gap='5px'>
              <Text title='get the intro pack' textTransform='upperCase' color="Red" fontSize='14px' />
              <i class="bi bi-arrow-right" style={{ color: '#D12C41' }}></i>
            </Flex>
          </Link>
        </Box>
      </Flex>
    </>
  );
}
export default MenuList;
