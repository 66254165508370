import React from 'react';
import { Container, Box } from '@chakra-ui/react';
import AminityBox from './AminityBox';
import * as Style from './Aminitybox.style';
import Text from '../SubHeading/SubHeading';
const OutStudio = (props) => {
    return (
        <Style.OutsideStudio>
            <Container maxW="container.xl">
                <Style.HeadingSection>
                    <Style.MainHeading fontSize={{ md: '60px', sm: '50px' }} color='#000' textTransform='uppercase'>OUTSIDE OUR STUDIOS</Style.MainHeading>
                </Style.HeadingSection>
                <Style.ContentSection>
                    <Style.ContentsectionBox>
                        <Style.MainHeading fontSize='40px' color='#000' textTransform='uppercase'>MOJO Cafe</Style.MainHeading>
                        <Text title="Visit MOJO Cafe, Perpetua’s in-house hub for health and wellness. We’ve got customized
shakes and grab and go goodies that will help you run faster, lift heavier and recover more quickly." as='p' fontSize="18px" color='#000' fontWeight='300' />
                    </Style.ContentsectionBox>
                    <Style.ContentsectionBox>
                        <Style.MainHeading fontSize='40px' color='#000' textTransform='uppercase'>Perpetua Clothing</Style.MainHeading>
                        <Text title="Perpetua is always working on collections and collaborations with the best brands that
will have you looking the part in and outside the gym. Check out our latest drops when you visit next time." as='p' fontSize="18px" color='#000' fontWeight='300' />
                    </Style.ContentsectionBox>
                </Style.ContentSection>
            </Container>
            <Box backgroundColor="#000" padding={{sm:"50px 0", md:"70px 0"}}>
                <Container maxW="container.xl">
                    <Style.AminitySectionHeading>
                        <Style.MainHeading fontSize='40px' color='#fff' textTransform='uppercase'>AMENITIES</Style.MainHeading>
                    </Style.AminitySectionHeading>

                    <Style.AminitySection>
                        {props.aminitibox.map((item, index) => {
                            return (
                                <AminityBox key={index} Icon={item.icon} Heading={item.heading} Description={item.description} alt={item.alt} />
                            )
                        })}
                    </Style.AminitySection>

                </Container>
            </Box>
        </Style.OutsideStudio>
    )
}

export default OutStudio;