import { Stack,Link} from '@chakra-ui/react';
import React from 'react';
import MenuList from './MenuList';
import {
  Drawer,
  DrawerBody,Box,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react'
import Button from '../Button/CustomeButton';
import { NavLink } from 'react-router-dom';
function Navigation({isDrawerOpen, closeDrawer, isSticky}) {
  
  return (
    <>
      <Drawer
        isOpen={isDrawerOpen}
        placement='top'
        onClose={closeDrawer}
      >
        <DrawerOverlay />
        <DrawerContent  className="dark_bg_menu">
          <DrawerCloseButton onClick={closeDrawer} />
          <DrawerBody>
            <Stack 
              className='main_navigation mobileDropdown' 
              color={"Black"} 
              spacing={0}
              align="center"
              justify={["center", "space-between", "flex-start", "flex-start"]}
              fontSize="xs2" 
              display={{base:'block' , md:'block', lg:'block'}}
              >
              <MenuList closeDrawer={closeDrawer} />
              
              <NavLink to='/pricing' className='_small-menu' onClick={closeDrawer}>
                <Button className='Download_APP' title='Buy Credits' margin={{ md:"20px 0 0", lg:"0"}} color={{ sm:"#000" , lg:"#000"}} />
              </NavLink>
              <Box>
                <NavLink to='/timetable' className='_small-menu' onClick={closeDrawer}>
                  <Button className='Download_APP' title='Book Class' margin={{ md:"20px 0 0", lg:"0"}} color={{ sm:"#000" , lg:"#000"}} />
                </NavLink>
              </Box>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Stack 
        className='main_navigation deskMenu' 
        color={ isSticky ? "#000" : "#fff"}
        spacing={0}
        align="center"
        justify={["center", "space-between", "flex-start", "flex-start"]}
        direction={["column", "row", "row", "row"]} fontSize="xs2" 
        display={{ base: 'none', md: 'none' }}
        height={{lg:'100%'}}
        >
          <MenuList />
      </Stack>
    </>
  );
}

export default Navigation;