import { Box, Stack, Button, Link } from "@chakra-ui/react";
import styled from "@emotion/styled/macro";
// import { device } from "../../Device.types";


export const LocationBody = styled(Box)(props => ({
    padding: 0,
    margin: 0,
}))

export const ClassSection = styled(Box)(props => ({
    background: '#fff',
    padding: '50px 0px 100px',
    "@media screen and (max-width:991px)": {
        padding: '50px 10px',
    },
}))


export const CBox = styled(Box)(props => ({
    width: '415px',
    // '&:nth-child(even)':{
    //     marginTop:'100px',

    //     "@media screen and (max-width:640px)": {
    //         marginTop:'0px',
    //     },
    // },
    // "@media screen and (max-width:1680px)": {
    //     width: '25%',
    // },
    "@media screen and (max-width:1366px)": {
        width: '33.33%',
        padding: '0px 15px'
    },
    "@media screen and (max-width:991px)": {
        width: '50%',
    },
    "@media screen and (max-width:640px)": {
        width: '100%',
        margin: '0px auto',
        marginBottom: '35px',
    },
}))

export const ClassWrapper = styled(Stack)(props => ({
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px',
    flexDirection: 'row',
}))

export const ButtonModal = styled(Button)(props => ({

    background: 'transparent',
    padding: '0px 18px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '20px',
    fontFamily: 'BlenderProBold',
    color: '#000',
    textTransform: ' uppercase',
    border: '2px solid #D12C41',
    borderRadius: '50px',
    height: '50px',
    minWidth: '180px',
    ":hover&": {
        background: '#D12C41',

    },

    "@media screen and (max-width:480px)": {
        fontSize: '16px',
        padding: '0px 10px',
        minWidth: '100px',
    }

}))

export const ButtonSection = styled(Box)(props => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '5px'
}))

export const LinkButton = styled(Link)(props => ({

    background: 'transparent',
    padding: '0px 18px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '20px',
    fontFamily: 'BlenderProBold',
    color: 'white',
    textTransform: ' uppercase',
    border: '2px solid #D12C41',
    borderRadius: '50px',
    height: '50px',
    minWidth: '180px',
    ":hover&": {
        background: '#D12C41',
        color: '#fff',

    },

    "@media screen and (max-width:480px)": {
        fontSize: '16px',
        padding: '0px 10px',
        minWidth: '100px',
    }

}))